import React from "react"

import Layout from "../components/layout"

const Tex = ({ pageContext }) => {
  const tex = pageContext.tex
  return (
    <Layout>
        <pre>{tex}</pre>
    </Layout>
  )
}

export default Tex
